<script lang="ts">
  import { slide } from "svelte/transition"
  import { WebsiteName } from "../../config"
  import { browser } from "$app/environment"

  let mobileMenuOpen = $state(false)
  let scrolled = $state(false)

  // Add scroll listener only on client-side
  $effect(() => {
    if (browser) {
      const handleScroll = () => {
        scrolled = window.scrollY > 20
      }
      window.addEventListener("scroll", handleScroll)
      return () => window.removeEventListener("scroll", handleScroll)
    }
  })
</script>

<div
  class="navbar-wrapper sticky top-0 z-50 bg-gradient-to-b from-amber-50/95 to-amber-100/95 backdrop-blur-sm border-b border-amber-200/30"
>
  <div class="navbar container mx-auto px-4">
    <!-- Logo/Brand Section -->
    <div class="flex-1">
      <a
        class="flex items-center gap-2 text-xl font-cinzel text-amber-900 hover:text-amber-800 transition-colors"
        href="/"
      >
        <!-- Ancient Temple Icon -->
        <!-- Replace the existing temple icon SVG with this enhanced version -->
        <svg
          class="w-8 h-8"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          aria-hidden="true"
        >
          <!-- Base/Platform -->
          <path
            d="M2 22h20M4 22v-2h16v2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />

          <!-- Steps -->
          <path d="M3 20h18" stroke-linecap="round" />

          <!-- Left Column -->
          <path d="M6 20V8" stroke-linecap="round" />
          <!-- Left Capital -->
          <path
            d="M4.5 8h3a1.5 1.5 0 0 0 0-3h-3a1.5 1.5 0 0 0 0 3z"
            fill="none"
          />

          <!-- Right Column -->
          <path d="M18 20V8" stroke-linecap="round" />
          <!-- Right Capital -->
          <path
            d="M16.5 8h3a1.5 1.5 0 0 0 0-3h-3a1.5 1.5 0 0 0 0 3z"
            fill="none"
          />

          <!-- Entablature/Top -->
          <path d="M3 5h18M4 3h16" stroke-linecap="round" />

          <!-- Volutes (spiral details) on capitals -->
          <path
            d="M4.5 7.5c0.2-0.4 0.8-0.4 1 0M16.5 7.5c0.2-0.4 0.8-0.4 1 0"
            stroke-linecap="round"
          />

          <!-- Optional: Column fluting (vertical grooves) -->
          <path
            d="M6 18l0-8M18 18l0-8"
            stroke-width="0.5"
            stroke-linecap="round"
            opacity="0.5"
          />
        </svg>
        <span class="hidden sm:inline">{WebsiteName}</span>
      </a>
    </div>

    <!-- Desktop Navigation -->
    <div class="flex-none hidden md:block">
      <ul class="menu menu-horizontal gap-1 font-medium">
        <li>
          <a
            href="/account/models"
            class="px-4 py-2 text-amber-900 hover:bg-amber-200/50 rounded-lg transition-colors"
          >
            3D Models
          </a>
        </li>
        <li>
          <a
            href="/account/map"
            class="px-4 py-2 text-amber-900 hover:bg-amber-200/50 rounded-lg transition-colors"
          >
            Map
          </a>
        </li>
        <li>
          <a
            href="/blog"
            class="px-4 py-2 text-amber-900 hover:bg-amber-200/50 rounded-lg transition-colors"
          >
            Blog
          </a>
        </li>
        <li>
          <a
            href="/pricing"
            class="px-4 py-2 text-amber-900 hover:bg-amber-200/50 rounded-lg transition-colors"
          >
            Pricing
          </a>
        </li>
        <li>
          <a
            href="/account"
            class="ml-2 px-6 py-2 bg-amber-900 hover:bg-amber-800 text-amber-50 rounded-lg transition-colors"
          >
            Account
          </a>
        </li>
      </ul>
    </div>

    <!-- Mobile Menu Button -->
    <div class="flex-none md:hidden">
      <button
        class="btn btn-ghost btn-circle"
        aria-label="Toggle navigation menu"
        onclick={() => (mobileMenuOpen = !mobileMenuOpen)}
      >
        <svg
          class="w-6 h-6 text-amber-900"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>
  </div>

  {#if mobileMenuOpen}
    <div
      class="absolute top-full left-0 right-0 bg-amber-50/95 backdrop-blur-sm border-b border-amber-200/30 py-4 px-6 md:hidden"
      transition:slide
    >
      <nav class="flex flex-col gap-3">
        <a
          href="/account/models"
          class="px-4 py-3 text-amber-900 hover:bg-amber-200/50 rounded-lg transition-colors"
        >
          3D Models
        </a>
        <a
          href="/account/map"
          class="px-4 py-3 text-amber-900 hover:bg-amber-200/50 rounded-lg transition-colors"
        >
          Map
        </a>
        <a
          href="/blog"
          class="px-4 py-3 text-amber-900 hover:bg-amber-200/50 rounded-lg transition-colors"
        >
          Blog
        </a>
        <a
          href="/pricing"
          class="px-4 py-3 text-amber-900 hover:bg-amber-200/50 rounded-lg transition-colors"
        >
          Pricing
        </a>
        <a
          href="/account"
          class="px-4 py-3 bg-amber-900 hover:bg-amber-800 text-amber-50 rounded-lg transition-colors text-center"
        >
          Account
        </a>
      </nav>
    </div>
  {/if}
</div>

<slot />

<style>
  :global(.font-cinzel) {
    font-family: "Cinzel", serif;
  }

  /* Custom scrollbar styling */
  :global(::-webkit-scrollbar) {
    width: 8px;
  }

  :global(::-webkit-scrollbar-track) {
    background: theme(colors.amber.50);
  }

  :global(::-webkit-scrollbar-thumb) {
    background: theme(colors.amber.700);
    border-radius: 4px;
  }

  :global(::-webkit-scrollbar-thumb:hover) {
    background: theme(colors.amber.800);
  }

  /* Smooth transitions */
  .navbar-wrapper {
    transition:
      background-color 0.3s ease,
      box-shadow 0.3s ease;
  }

  /* Add subtle shadow on scroll */
  :global(.scrolled) .navbar-wrapper {
    @apply shadow-sm;
  }
</style>
